/* You can add global styles to this file, and also import other style files */
// @tailwind base;
// @tailwind components;
// @tailwind utilities;
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import '~quill/dist/quill.bubble.css';

html,
body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

.anticon {
  vertical-align: 0 !important;
}

.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@media (device-width: 768px) and (device-height: 1024px) {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

/* Importing Bootstrap SCSS file. */
@import './node_modules/bootstrap/scss/bootstrap';

/* Importing Datepicker SCSS file. */
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker';

ngx-material-timepicker-container {
  position: absolute;
  z-index: 20000;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: #fff;
}

.fc .fc-timegrid-slot {
  border-bottom: 0;
  height: 50px !important;
}

.fc .fc-timegrid-col-bg .fc-non-business {
  // z-index: 1;
  background-image: linear-gradient(
    45deg,
    #ff738d 25%,
    #f5f5f5 25%,
    #f5f5f5 50%,
    #ff738d 50%,
    #ff738d 75%,
    #f5f5f5 75%,
    #f5f5f5 100%
  );
  background-size: 84.85px 84.85px;
  cursor: not-allowed;
}

.fc .fc-timegrid-slot {
  border-bottom: 0;
  height: 2.7em;
}

@media (min-width: 481px) and (max-width: 767px) {
  nz-card .ant-card-head {
    display: none;
  }
}
